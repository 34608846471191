import React from "react";
import s from './ShopStatistics.module.css';

const ShopStatistics = ()=>{

    return(
        <div>
            ShopStatistics
        </div>
    )
}

export default ShopStatistics