import React from "react";
import s from "./Navigation.module.css";

const Navigation = ({show}) =>{
    return(
        <div className={s.wrap}>
            
        </div>
    )
}
export {Navigation};