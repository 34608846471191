import React from "react";
import s from './ShopSupport.module.css';

const ShopSupport = ()=>{

    return(
        <div>
            ShopSupport
        </div>
    )
}

export default ShopSupport